import { createStore } from '~/store/createStore'

export const useGoOffStore = createStore<{
  go?: number
  off?: number
  virtualSymbol: string
  virtualEntryDate: string
  virtualQty: number
}>((set, get) => {
  return {
    go: undefined,
    off: undefined,
    virtualSymbol: '',
    virtualEntryDate: '',
    virtualQty: 0,
  }
})
