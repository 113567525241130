import { useCallback } from 'react'
import { AnyFunction } from 'tsdef'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { ChartingModule } from '~/modules/SDK/chart4/ChartingModule'

/**
 * # chart2/4 具兼容性供遷移用之取出函式
 *
 * ### 兼容 Chart2 或 Chart4 的 instance 傳入
 *
 * ### 讓你暫時不需要大改 chart2 的組件或是 hook
 *
 * 你傳入 chart2 或者 chart4 的 instance
 *
 * 並且傳入「如果是 chart2 函式具體實現」以及「如果是 chart4 函式具體實現」
 *
 * 返回對應的可用函式
 */
export const useChartingFn = <F extends AnyFunction>(
  charting: ChartTypes.ChartingV2Instance | ChartingModule,
  fn: {
    chart2(charting: ChartTypes.ChartingV2Instance): F
    chart4(charting: ChartingModule): F
  },
) => {
  const fallbackFn = useCallback(() => {
    // Chart4
    if (charting instanceof ChartingModule) {
      return fn.chart4(charting)
    }

    // Chart2
    return fn.chart2(charting)
  }, [charting, fn])

  return fallbackFn()
}
