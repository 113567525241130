import { proxy } from 'valtio'

export const symbolGroupStore = proxy({
  /** 成交量篩選量 */
  volumeLimit: 0,
  /**
   * 成交量篩選模式
   *
   * - `>`: 成交量大於 N 則顯示
   * - `<`: 成交量小於 N 則顯示
   */
  volumeLimitMode: '>' as '>' | '<',
  /** 成交量篩選模式：是大於嗎 */
  get volumeLimitModeIsGT() {
    return symbolGroupStore.volumeLimitMode === '>'
  },
  /** 成交量篩選模式：是小於嗎 */
  get volumeLimitModeIsLT() {
    return symbolGroupStore.volumeLimitMode === '<'
  },
})
