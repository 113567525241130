import { css } from '@emotion/react'
import { createTheme, Paper } from '@mui/material'
import React, { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'

import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useSignalrQuoteSort } from '~/modules/investment-consultant/signalrQuoteSort/useSignalrSortCondition'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import type { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import type { Socket } from '~/modules/SDK/socket2/Socket'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { symbolGroupStore } from '~/modules/investment-consultant/SymbolGroupTable/symbolGroupStore'
import ThemeLayout from '~/components/theme/ThemeLayout'
import { useSymbolStore } from '~/modules/symbolQuote/simple/useSymbolStore'
import SimpleQuoteBodyItem from '~/modules/symbolQuote/simple/SimpleQuoteBodyItem'
import SimpleQuoteHeader from '~/modules/symbolQuote/simple/SimpleQuoteHeader'
import { VirtualExchangeHolding } from '~/modules/virtualExchange/useVirtualExchangeResource'
import { useTimeout, useUpdateEffect } from 'react-use'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import { fontWeight600 } from '~/css/font'
import { scrollbar2Css } from '~/css/scrollbarCss'
import { BsXSquareFill } from 'react-icons/bs'
import { isEmpty } from 'lodash'
import { useGoOffStore } from '~/trades/indicators/weng888/stores/useGoOffStore'
import { useChartingSymbol } from '~/modules/SDK/chart4/hooks/useChartingSymbol'
import { useChartingChangeIndicatorsFn } from '~/modules/SDK/chart4/hooks/useChartingChangeIndicators'
import { useChartingChangeSymbolIntervalFn } from '~/modules/SDK/chart4/hooks/useChartingChangeSymbolIntervalFn'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    text: { primary: '#333333' },
    background: { paper: '#eeeeee' },
  },
})

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    text: { primary: '#ffffff' },
    background: { paper: '#222222' },
  },
})

export const SimpleQuoteList = memo(function SimpleQuoteList(props: {
  data: string[]
  handleClick?: React.MouseEventHandler<HTMLDivElement>
  showStockFutures?: boolean
}) {
  const useSymbolState = useSnapshot(useSymbolStore)
  const showStockFutures = props.showStockFutures ?? true

  useEffect(() => {
    useSignalrStore.getState().subscribeAdd([...(props.data || [])], 'ohlc')
    return () => {
      useSignalrStore.getState().subscribeRemove([...(props.data || [])], 'ohlc')
    }
  }, [props.data])

  const sortData = useSignalrQuoteSort(props.data) ?? []

  return (
    <ThemeLayout
      lightTheme={lightTheme}
      darkTheme={darkTheme}
    >
      <div
        css={css`
          ${flex.v.default};
          width: 100%;
          height: 100%;
        `}
      >
        <SimpleQuoteHeader.Default />
        <div css={styledScrollBarCss}>
          {sortData?.map(symbol => (
            <span
              key={symbol}
              onClick={() => (useSymbolStore.currentSymbol = symbol as Signalr.SymbolString)}
            >
              <SimpleQuoteBodyItem.Default
                currentSymbol={useSymbolState.currentSymbol}
                symbol={symbol as Signalr.SymbolString}
                showStockFutures={showStockFutures}
              />
            </span>
          ))}
        </div>
      </div>
    </ThemeLayout>
  )
})

/** 持有中表格 資料格式: VirtualExchangeHolding[] */
export const HoldingQuoteListInteractWithChart = memo(
  function HoldingQuoteListInteractWithChart(props: {
    indicators?: ChartTypes.Indicator[]
    data?: VirtualExchangeHolding[]
    chart: ChartTypes.ChartingV2OrV4
    handleClick?: React.MouseEventHandler<HTMLDivElement>
    hideHeader?: boolean
    showStockFutures?: boolean
  }) {
    const watchListState = useSnapshot(symbolGroupStore)
    const data = props.data ?? []
    const removeNoQuotedata = data?.filter(datum => datum.marketPrice !== null)
    const showStockFutures = props.showStockFutures ?? true
    const currentSymbol = useChartingSymbol(props.chart)()
    const changeIndicators = useChartingChangeIndicatorsFn(props.chart)
    const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props.chart)

    const resultData = removeNoQuotedata.filter(datum => {
      const quote = useSignalrStoreValueOHLC.getState().value[datum.symbol]
      const limitMode = symbolGroupStore.volumeLimitMode
      const volume = quote?.volume ?? 0

      if (quote) {
        if (limitMode === '>') return volume >= watchListState.volumeLimit
        else return volume <= watchListState.volumeLimit
      }
      return true
    })

    const handleChangeSymbol = (datum: {
      averagePrice: number
      symbol: string
      lastModifiedDatetime: string
      qty: number
    }) => {
      useGoOffStore.setState({ go: datum.averagePrice, off: datum.averagePrice * 0.9 })
      useGoOffStore.setState({ virtualSymbol: datum.symbol })
      useGoOffStore.setState({ virtualEntryDate: datum.lastModifiedDatetime })
      useGoOffStore.setState({ virtualQty: datum.qty })
    }

    return (
      <ThemeLayout
        lightTheme={lightTheme}
        darkTheme={darkTheme}
      >
        <div
          css={css`
            ${flex.v.default};
            width: 100%;
            height: 100%;
          `}
        >
          {props.hideHeader ? null : <SimpleQuoteHeader.Holding />}
          <div
            css={css`
              ${styledScrollBarCss};
              height: ${props.hideHeader === true ? '100%' : 'calc(100% - 40px)'};
            `}
          >
            {resultData?.map(datum => {
              const symbol = datum?.symbol as Signalr.SymbolString
              const { pnl, qty, averagePrice, lastModifiedDatetime } = datum
              return currentSymbol !== symbol ? (
                <div
                  key={datum.symbol}
                  onClick={event => {
                    props.handleClick?.(event)
                    if (props.indicators) {
                      changeIndicators(props.indicators)
                    }
                    changeSymbolInterval({ symbol })
                    handleChangeSymbol(datum)
                  }}
                >
                  <SimpleQuoteBodyItem.Holding
                    currentSymbol={currentSymbol}
                    symbol={symbol}
                    profit={pnl ?? 0}
                    equity={qty}
                    entryPrice={averagePrice}
                    date={lastModifiedDatetime}
                    showStockFutures={showStockFutures}
                  />
                </div>
              ) : (
                <SimpleQuoteBodyItem.Holding
                  key={symbol}
                  currentSymbol={currentSymbol}
                  symbol={symbol}
                  profit={pnl ?? 0}
                  equity={qty}
                  entryPrice={averagePrice}
                  date={lastModifiedDatetime}
                  showStockFutures={showStockFutures}
                />
              )
            })}
          </div>
        </div>
      </ThemeLayout>
    )
  },
)

/** 排列顯示data中的股票 帶入股票代號之陣列即可 [2330,2609,...] */
export const SimpleQuoteListInteractWithChart = memo(
  function SimpleQuoteListInteractWithChart(props: {
    indicators?: ChartTypes.Indicator[]
    data: string[]
    chart: ChartTypes.ChartingV2OrV4
    handleClick?: (symbol: string) => unknown
    noAutoSubscribe?: boolean
    showStockFutures?: boolean
  }) {
    const currentSymbol = useChartingSymbol(props.chart)()
    const changeIndicators = useChartingChangeIndicatorsFn(props.chart)
    const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props.chart)
    const noAutoSubscribe = props.noAutoSubscribe ?? false
    const showStockFutures = props.showStockFutures ?? true

    useEffect(() => {
      if (noAutoSubscribe) return
      useSignalrStore.getState().subscribeAdd([...(props.data || [])], 'ohlc')
      return () => {
        useSignalrStore.getState().subscribeRemove([...(props.data || [])], 'ohlc')
      }
    }, [noAutoSubscribe, props.data, props.noAutoSubscribe])

    const sortData = useSignalrQuoteSort(props.data) ?? []

    const symbolsInfo = useSignalrStoreValueOHLC(state => state.useGroupOHLC(sortData as string[]))

    const filteredData = symbolsInfo.filter(data => {
      const { volumeLimitModeIsGT, volumeLimitModeIsLT } = symbolGroupStore
      if (volumeLimitModeIsGT) {
        return (data?.volume ?? 0) >= symbolGroupStore.volumeLimit
      }
      if (volumeLimitModeIsLT) {
        return (data?.volume ?? 0) <= symbolGroupStore.volumeLimit
      }
      return true
    })

    return (
      <ThemeLayout
        lightTheme={lightTheme}
        darkTheme={darkTheme}
      >
        <div
          css={css`
            ${flex.v.default};
            width: 100%;
            height: 100%;
          `}
        >
          <SimpleQuoteHeader.Default />
          {filteredData.length === 0 ? (
            <div
              css={css`
                ${flex.v.allCenter};
                width: 100%;
                height: calc(100% - 40px);
              `}
            >
              沒有商品
            </div>
          ) : (
            <div css={styledScrollBarCss}>
              {filteredData?.map((data, index) => {
                const symbol = data?.symbol as Signalr.SymbolString

                return (
                  <div
                    key={index}
                    onClick={event => {
                      props.handleClick?.(symbol)

                      if (currentSymbol !== symbol) {
                        changeSymbolInterval({ symbol: symbol })
                      }

                      if (props.indicators) {
                        changeIndicators(props.indicators)
                      }
                    }}
                  >
                    <SimpleQuoteBodyItem.Default
                      currentSymbol={currentSymbol}
                      symbol={symbol}
                      showStockFutures={showStockFutures}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </ThemeLayout>
    )
  },
)

/** 顯示自選股群組 並附帶垂直滾動條 */
export const WatchListWithChart = memo<
  ReactProps<{
    groupName: string
    useChart: ChartTypes.ChartingV2OrV4
    indicators?: ChartTypes.Indicator[]
    handleClick?: React.MouseEventHandler<HTMLDivElement>
    hideHeader?: boolean
    showStockFutures?: boolean
  }>
>(function WatchListWithChart(props) {
  const { state, acts } = useSymbolWatchList(props.groupName)
  const symbolListArray = state.symbolListArray
  const currentSymbol = useChartingSymbol(props.useChart)()
  const showStockFutures = props.showStockFutures ?? true
  const changeIndicators = useChartingChangeIndicatorsFn(props.useChart)
  const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props.useChart)

  useEffect(() => {
    useSignalrStore.getState().subscribeAdd([...(symbolListArray || [])], 'ohlc')
    return () => {
      useSignalrStore.getState().subscribeRemove([...(symbolListArray || [])], 'ohlc')
    }
  }, [symbolListArray])

  const sortData = useSignalrQuoteSort(symbolListArray?.map(datum => datum)) ?? []

  /** 載入緩衝時間 */
  const [initializeReady, reset] = useTimeout(1250)
  useUpdateEffect(() => {
    reset()
  }, [initializeReady])

  return (
    <ThemeLayout
      lightTheme={lightTheme}
      darkTheme={darkTheme}
    >
      <div
        className={props.className}
        css={css`
          ${flex.v.default};
          width: 100%;
          height: 100%;
        `}
      >
        {props.hideHeader ? null : <SimpleQuoteHeader.WatchList />}
        <div css={styledScrollBarCss}>
          {isEmpty(sortData) ? (
            <Paper css={watchlistEmptyCss}>{initializeReady() ? '尚未加入自選股' : '載入中'}</Paper>
          ) : (
            sortData?.map(symbol => (
              <div
                css={watchlistItemBoxCss}
                key={symbol}
              >
                {currentSymbol !== symbol ? (
                  <div
                    style={{ width: '100%' }}
                    onClick={event => {
                      props.handleClick?.(event)
                      {
                        props.indicators && changeIndicators(props.indicators)
                      }
                      changeSymbolInterval({ symbol: symbol })
                    }}
                  >
                    <SimpleQuoteBodyItem.Default
                      currentSymbol={currentSymbol}
                      symbol={symbol as Socket.SymbolString}
                      showStockFutures={showStockFutures}
                    />
                  </div>
                ) : (
                  <div style={{ width: '100%' }}>
                    <SimpleQuoteBodyItem.Default
                      currentSymbol={currentSymbol}
                      symbol={symbol as Socket.SymbolString}
                      showStockFutures={showStockFutures}
                    />
                  </div>
                )}
                <div
                  css={removeButtonCss}
                  onClick={() => acts.removeSymbol(symbol as Socket.SymbolString)}
                >
                  <BsXSquareFill />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </ThemeLayout>
  )
})

const styledScrollBarCss = css`
  ${scrollbar2Css}
  height: calc(100% - 40px);
`

const watchlistEmptyCss = css`
  ${flex.v.allCenter};
  width: 100%;
  height: 100%;
  font-size: 16px;
`

const watchlistItemBoxCss = css`
  ${flex.inline.allCenter};
  width: 100%;
  height: 40px;
  ${fontWeight600};
  border-radius: 5px;
  font-size: 14px;
  margin: 2px 2px 2px 2px;
`

const removeButtonCss = css`
  ${flex.h.allCenter};
  width: 7%;
  height: 100%;
  color: #a3a7a6;
  ${fontWeight600};
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0px 2px 0px 2px;
  &:hover {
    transition: 0.3s;
    background-color: #ef5956;
    color: #eeeeee;
  }
`
