import { useChartingFn } from '~/modules/SDK/chart4/hooks/useChartingFn'

export const useChartingChangeSymbolIntervalFn = (
  charting: ParametersHead<typeof useChartingFn>,
) => {
  type Params = {
    symbol?: string
    interval?: string
  }

  return useChartingFn(charting, {
    chart2: chartInstance => (options: Params) => {
      options.symbol && chartInstance.getState().changeSymbol(options.symbol)

      options.interval &&
        chartInstance
          .getState()
          .widget?.activeChart()
          .setResolution(options.interval as any, () => {
            //
          })
    },
    chart4: chartInstance => (options: Params) => {
      options.symbol && chartInstance.changeSymbol(options.symbol)

      options.interval && chartInstance.changeInterval(options.interval)
    },
  })
}
