import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { useChartingFn } from '~/modules/SDK/chart4/hooks/useChartingFn'

export const useChartingChangeIndicatorsFn = (charting: ParametersHead<typeof useChartingFn>) => {
  type Params = ChartTypes.Indicator[]

  return useChartingFn(charting, {
    chart2: chartInstance => (options: Params) => {
      chartInstance.getState().changeIndicators(options)
    },
    chart4: chartInstance => (options: Params) => {
      chartInstance.replaceIndicators(options)
    },
  })
}
