import { useSnapshot } from 'valtio'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { useChartingFn } from '~/modules/SDK/chart4/hooks/useChartingFn'

export const useChartingSymbol = (charting: ChartTypes.ChartingV2OrV4) => {
  return useChartingFn(charting, {
    chart2: chartInstance =>
      function useSymbol() {
        return chartInstance((s: { symbol: string }) => s.symbol)
      },
    chart4: chartInstance =>
      function useSymbol() {
        return useSnapshot(chartInstance).symbol
      },
  })
}
